import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  NativeSelect,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { memo, useState } from "react";


import { CustomizeToxicFilters } from "../../components/Properties/screenieTypes";
import http from '../../net/http-common';
import { OtherEndPoint } from "../../config";
import { errorMessages } from "../../common_variables/ErrorMsgs";
import IndefiniteLoader from "../common/IndefiniteLoader";
import { GridColDef, GridFilterItem, GridFilterOperator } from "@mui/x-data-grid";

function BrenkFilter({
  openCustomizeToxicFilterDialog,
  setOpenCustomizeToxicFilterDialog,
  customizeToxicFilters,
  allSmilesAnd2dForToxicFilters,
  setToxicCol,
  setToxicRow,
  setIsTopLevelEnable
}: {
  openCustomizeToxicFilterDialog: boolean;
  setOpenCustomizeToxicFilterDialog: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  customizeToxicFilters: CustomizeToxicFilters[];
  allSmilesAnd2dForToxicFilters:any[];
  setToxicCol:React.Dispatch<React.SetStateAction<any[]>>;
  setToxicRow:React.Dispatch<React.SetStateAction<any[]>>,
  setIsTopLevelEnable:React.Dispatch<React.SetStateAction<boolean>>

}) {

  //  prepared input for brenk filter api
  const [preparedInputs, setPreparedInputs] = useState<any>({
    input_json: {
      BRENK: {
        action: "SELECT_ALL",
        group: [],
      },
      NIH: {
        action: "SELECT_ALL",
        group: [],
      },
      ZINC: {
        action: "SELECT_ALL",
        group: [],
      },
      PAINS_A: {
        action: "SELECT_ALL",
        group: [],
      },
      PAINS_B: {
        action: "SELECT_ALL",
        group: [],
      },
      PAINS_C: {
        action: "SELECT_ALL",
        group: [],
      },
    },
    list_of_smiles: [],
    top_level: false,
  });

  const[showError,setShowError]=useState<boolean>(false);
  const[errorMsg,setErrorMsg]=useState<string>("");
  const[loading,setLoading]=useState<boolean>(false);

  // Custom filter operator to filter based on "PASS" or "FAIL"
const customPassFailFilterOperator: GridFilterOperator = {
  label: "contains",
  value: "contains",
  getApplyFilterFn: (filterItem: GridFilterItem) => {
    if (!filterItem.value) {
      return null; // Don't apply any filter if no value is provided
    }

    return ({ value }) => {
     
      
      const displayValue = value === 1 ? "PASS" : "FAIL";

      // Check if the displayValue matches the filter value (case-insensitive)
      return displayValue.toLowerCase().includes(filterItem.value.toLowerCase());
    };
  },
  InputComponent: ({ item, applyValue }) => {
    
    return (
      <Box mt={3}>
        <input type="text" onChange={(e)=> applyValue({ ...item, value: e.target.value })} style={{border:"none",borderBottom:"1px solid grey",fontSize:"1rem",width:"150px"}} />
      </Box>
    );
  },
};

   // Mai filter properties columns
   let customizeTosicFilterColumns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      type: "number",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "smiles",
      headerName: "SMILES",
      editable: false,
      headerAlign: "center",
      width: 200,
    },
    {
      field: "svg",
      headerName: "2D Representation",
      description: "This column has images and is not sortable.",
      sortable: false,
      filterable: false,
      disableExport: true,
      width: 140,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <img
          src={`data:image/svg+xml;base64,${btoa(params.value)}`}
          alt="2D svg representation"
          style={{
            height: "200px",
            width: "80%",
            transition: "transform 0.3s ease-in-out",
          }}
          onMouseOver={(e) => {
            e.currentTarget.style.transform = "scale(150%)";
          }}
          onMouseOut={(e) => {
            e.currentTarget.style.transform = "scale(100%)";
          }}
        />
      ),
    },
     
    
  ];
  

  // updating groups of inputs
  const handleGroupChange = (event: any, value: string, filterType: string) => {
    
    if(!value)
      return;

    setPreparedInputs((prevInputs: any) => ({
      ...prevInputs,
      input_json: {
        ...prevInputs.input_json,
        [filterType]: {
          ...prevInputs.input_json[filterType],
          group: [...prevInputs.input_json[filterType].group,value], // Update the group array with the selected values
        },
      },
    }));
  };

  // updating  group type
  const handleSelectChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
    filterType: string
  ) => {
    const value = event.target.value;

    setPreparedInputs((prevInputs: any) => ({
      ...prevInputs,
      input_json: {
        ...prevInputs.input_json,
        [filterType]: {
          ...prevInputs.input_json[filterType],
          action: value, // Update the action for the corresponding filter type
          group: (value==="SELECT_ALL"||value==="IGNORE_ALL")?[]:prevInputs.input_json[filterType].group
        },
      },
    }));
  };

  // delete subrules from chips section
  const deleteChips = (filterType: string, subrule: string) => {
    setPreparedInputs((prevInputs: any) => ({
      ...prevInputs,
      input_json: {
        ...prevInputs.input_json,
        [filterType]: {
          ...prevInputs.input_json[filterType],
          group: prevInputs.input_json[filterType].group.filter(
            (item: string) => item !== subrule
          ), // Remove the specific subrule from the group
        },
      },
    }));
  };

 

  // console.log('skd',allSmilesAnd2dForToxicFilters);
  

  // submit handler
  const submitInputFilters=async()=>{

    setLoading(true);

    const inputFilters= preparedInputs;

    const allSmiles=allSmilesAnd2dForToxicFilters.map((item)=>item.smiles)

    inputFilters.list_of_smiles=allSmiles;

   

    try {

      const response = await http.post(`${OtherEndPoint}/toxgroups/filter/`,inputFilters,{
        headers:{
          Accept:"application/json",
          "Content-Type":"application/json"
        }
      });

        let flagToGetOtherKeys:boolean=true;
        let allKeys:any=[];
        let removedKeys=['id','smiles','svg']
      const finalCustomizeFilterOutput:any[]= response.data.map((res:any,index:number)=>{

          let brenkObj=res["BRENK"];
          let nihObj=res["NIH"];
          let painsaObj=res["PAINS_A"];
          let painsbObj=res["PAINS_B"];
          let painscObj=res["PAINS_C"];
          let zincObj=res["ZINC"];

          const smileobjectwithsvg=allSmilesAnd2dForToxicFilters.filter((item)=>item.smiles===res.smiles);

          let finalObject={id:index,smiles:res.smiles,...brenkObj,...nihObj,...painsaObj,...painsbObj,...painscObj,...zincObj,svg:smileobjectwithsvg[0].svg};

          if(flagToGetOtherKeys){
            allKeys=Object.keys(finalObject).filter((item)=>!removedKeys.includes(item));
            flagToGetOtherKeys=false;
          }

          // console.log('finalobj',finalObject);
          return finalObject;
      });


      const dynamicColumns = allKeys.map((field: any) => ({
        field,
        headerName: field.toUpperCase(),
        headerAlign: "center",
        align: "center",
        width: 150,
        type: "string",
        renderCell: (params: any) => {
          return (
            <Box
              sx={{
                color:params.value===1?"green":"red"
              }}
            >
              {params.value===1?"PASS":"FAIL"}
            </Box>
          )
        },
        filterOperators: [customPassFailFilterOperator],
      }));

      customizeTosicFilterColumns=[...customizeTosicFilterColumns,...dynamicColumns];
      setIsTopLevelEnable(true);
      setToxicCol(customizeTosicFilterColumns);
      setToxicRow(finalCustomizeFilterOutput);
      
      
      setPreparedInputs((prevInputs: any) => ({
        ...prevInputs,
        input_json: {
          ...prevInputs.input_json,
          BRENK: {
            action: "SELECT_ALL",
            group: [],
          },
          NIH: {
            action: "SELECT_ALL",
            group: [],
          },
          ZINC: {
            action: "SELECT_ALL",
            group: [],
          },
          PAINS_A: {
            action: "SELECT_ALL",
            group: [],
          },
          PAINS_B: {
            action: "SELECT_ALL",
            group: [],
          },
          PAINS_C: {
            action: "SELECT_ALL",
            group: [],
          },
        },
      }));
      setOpenCustomizeToxicFilterDialog(false);
      
    } catch (error) {
      setErrorMsg(errorMessages.serverError);
      setShowError(true);
    }
    
    setLoading(false);
    

  }
  

  // console.log('preapreddata',preparedInputs);
  

  return (
    <>
     <Snackbar
        open={showError}
        autoHideDuration={9000}
        sx={{ width: "50%" }}
        onClose={() => {
          setShowError(false);
        }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => {
            setShowError(false);
          }}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {errorMsg}
        </Alert>
      </Snackbar>
      <Box>
        <Dialog
          open={openCustomizeToxicFilterDialog}
          onClose={() => {
            setPreparedInputs((prevInputs: any) => ({
              ...prevInputs,
              input_json: {
                ...prevInputs.input_json,
                BRENK: {
                  action: "SELECT_ALL",
                  group: [],
                },
                NIH: {
                  action: "SELECT_ALL",
                  group: [],
                },
                ZINC: {
                  action: "SELECT_ALL",
                  group: [],
                },
                PAINS_A: {
                  action: "SELECT_ALL",
                  group: [],
                },
                PAINS_B: {
                  action: "SELECT_ALL",
                  group: [],
                },
                PAINS_C: {
                  action: "SELECT_ALL",
                  group: [],
                },
              },
            }));
            setOpenCustomizeToxicFilterDialog(false)
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="brenk-dialog"
          sx={{
            "& .css-1q3t5pl-MuiPaper-root-MuiDialog-paper": {
              maxWidth: "1100px",
            },
            "& .css-esw9ho": {
              maxWidth: "1100px !important",
            },
          }}
        >
          <DialogTitle id="alert-dialog-title" textAlign={"center"}>
            <Typography variant="h5">CUSTOMIZE TOXIC GROUPS</Typography>
          </DialogTitle>

          <DialogContent>
            {customizeToxicFilters.map((customFilter) => (
              <Box
                key={customFilter.type}
                sx={{
                  display: "flex",
                  gap: "10em",
                  // alignItems: "center",
                  mb: 5,
                  mt: 1,
                }}
              >
                <Typography variant="h6" width={"30%"}>
                  {customFilter.type}
                </Typography>
                <FormControl
                  fullWidth
                  sx={{
                    width: "50%",
                  }}
                >
                  <InputLabel variant="standard" htmlFor="uncontrolled-native">
                    {customFilter.type}
                  </InputLabel>
                  <NativeSelect
                    defaultValue={"Select All"}
                    inputProps={{
                      name: "BRENK",
                      id: "uncontrolled-native",
                    }}
                    onChange={(event) =>
                      handleSelectChange(event, customFilter.type)
                    } // Pass event and filter type
                  >
                    <option value={"SELECT_ALL"}>SELECT ALL</option>
                    <option value={"SELECT_FEW"}>SELECT FEW</option>
                    <option value={"IGNORE_ALL"}>IGNORE ALL</option>
                    <option value={"IGNORE_FEW"}>IGNORE FEW</option>
                  </NativeSelect>
                </FormControl>

                <Box width={1000}>
                  <Autocomplete
                  
                    options={customFilter.subrules}
                    disabled={
                      preparedInputs.input_json[customFilter.type].action ===
                        "SELECT_ALL" ||
                      preparedInputs.input_json[customFilter.type].action ===
                        "IGNORE_ALL"
                    }
                    onChange={(event, value) => handleGroupChange(event, value, customFilter.type)} // Update group on selection
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={customFilter.type + " Subrules"}
                      />
                    )}
                  />
                  {preparedInputs.input_json[customFilter.type].group.map(
                    (subrule:string) => (
                      <Chip
                        size="medium"
                        label={subrule}
                        onDelete={() => deleteChips(customFilter.type, subrule)}
                        sx={{ m: 1 }}
                      />
                    )
                  )}
                </Box>
              </Box>
            ))}
          </DialogContent>
          <DialogActions>
            <Button
              size="large"
              onClick={() => setOpenCustomizeToxicFilterDialog(false)}
            >
              <Typography variant="h6">Cancel</Typography>
            </Button>
            <Button size="large" onClick={submitInputFilters}>
              <Typography variant="h6" >Submit</Typography>
            </Button>
          </DialogActions>
          <IndefiniteLoader state={loading}/>
        </Dialog>
      </Box>
    
    </>
  );
}

export default memo(BrenkFilter);
