interface LeaderCardsTypes {
  label: string;
  link: string;
  description: string;
  dashIcon: string;
  navlabel: string;
}

export const leaderCards: LeaderCardsTypes[] = [
  // {
  //   label: "Interaction Adder",
  //   link: "interactions",
  //   description: "",
  //   dashIcon: "images/dashcard-images/diffmol-dashcard.png",
  //   navlabel: "Interaction Adder",
  // },
  {
    label: "Molecule CoPilot",
    link: "copilot",
    description:
      "Optimize molecules with interactive natural language feedback",
    dashIcon: "images/dashcard-images/molecule_copilot5.jpg",
    navlabel: "Molecule CoPilot",
  },
  {
    label: "R Group Replacements App",
    link: "r-groups",
    // icon: "/images/icons/ligand-prep.svg",
    description: "R-group replacement for drug generation and optimization",
    dashIcon: "images/r-groups.png",
    navlabel: "R Groups",
  },
  {
    label: "Bioisosteric Replacement",
    link: "boisosteric",
    // icon: "/images/icons/ligand-prep.svg",
    description:
      "Optimize molecular structures while preserving key interactions",
    dashIcon: "images/bioiso_logo1.png",
    navlabel: "Bioisosteric Replacement",
  },

  {
    label: "Leader",
    navlabel: "Leader",
    link: "leader",
    // icon: "/images/icons/property-filter.svg",
    description: "Add the description of leader here .....................",
    dashIcon: "/images/dashcard-images/screenie-dashcard.png",
  },
];
