import React, { useEffect } from "react";
import { useState } from "react";
import Button from "@mui/material/Button";
import { Typography, Popover, Box } from "@mui/material";

const taskNames: any = {
  "maimol": {
    tagmol_id: "Stage 1",
    unidock_1_id: "Stage 2",
    desert_id: "Stage 3",
    unidock_2_id: "Stage 4"
  },

  "ranker": {
    screenie_id: "Stage 1",
    admet_id: "Stage 2",
    unidock_id: "Stage 3",
    lift_id: "Stage 4"
  },
  "reinvent": {
    reinvent_id: "Stage 1",
    admet_id: "Stage 2"
  }
};

const statusColor: any = {
  SUCCESS: {
    color: "#0d600d",
  },
  FAILURE: {
    color: "#f52015",
  },
  STARTED: {
    color: "#23ae23",
  },
  REVOKED: {
    color: "#f52015",
  },
  RECEIVED: {
    color: "black",
  },
};

function SubTaskStatus({ subtasks, model }: { subtasks: any, model: any }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [moduleStatus, setModuleStatus] = useState<any[]>([]);

  //   const inputData = JSON.parse(data);
  // //console.log(inputData);
  // console.log("subtask", subtasks);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  // //console.log(data);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    if (subtasks) {
      let allModules = Object.entries(subtasks).map(([key, value]) => {
        const typedValue = value as { state: string }; // Assert the type
        return { state: typedValue.state, key: key };
      });
      // console.log("modules", allModules);

      // Sort moduelarr based on the taskNames sequence
      const sortedModuleArr = allModules.sort((a, b) => {
        const stageA = taskNames[model][a.key];
        const stageB = taskNames[model][b.key];

        // Compare the stages (e.g., "Stage 1", "Stage 2", etc.)
        return stageA.localeCompare(stageB);
      });

      // console.log(sortedModuleArr);
      setModuleStatus(sortedModuleArr);
    }
  }, [subtasks]);

  return (
    <>
      <Button aria-describedby={id} variant="text" onClick={handleClick}>
        Show
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box sx={{ p: 2 }}>
          {subtasks &&
            moduleStatus.map((item: any) => {
              return (
                <Typography key={item.key}>
                  <strong>{taskNames[model][item.key]}:</strong>{" "}
                  <span
                    style={{
                      color: item ? statusColor[item.state].color : "black",
                    }}
                  >
                    {item ? item.state : "N/A"}
                  </span>
                </Typography>
              );
            })}
        </Box>
      </Popover>
    </>
  );
}

export default SubTaskStatus;
