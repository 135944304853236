// ModuleManager.ts
export class DashBoardManager {
    private comingSoonModules: string[] = ["MolFil", "GENFil"];

    private aiModules:string[]=["MAIMol","ADMET","Lead Optimisation","MolFil","GENFil","Molecule CoPilot","Bioisosteric Replacement","Leader"];
  
    // Method to get the coming soon modules
    public getComingSoonModules(): string[] {
      return this.comingSoonModules;
    }

    public getAIModules(): string[] {
      return this.aiModules;
    }
  }