import { createBrowserRouter, RouteObject } from "react-router-dom";
import Layout from "../components/layout/Layout";
import {
  Dashboard,
  KViewer,
  LigandPrep,
  ProtienPreprocess,
  PDBInfo,
  Properties,
  PropertiesHome,
  DiffMolHome,
  LigandHome,
  PDBInfoDetails,
  // Limo,
  // JobSubmit,
  DockingDashboard,
  PageNotFound,
  AutoDockVisualize,
  AutoDockViewer,
} from "../views";
import TargetDiff from "../components/models/targetdiff/TargetDiff";
import Login from "../components/user/Login";
import Signup from "../components/user/Signup";
import DyMean from "../components/models/DyMean";
import TargetDiffSubmitJob from "../components/models/targetdiff/TargetDiffSubmitJob";
import TargetDiffJobs from "../components/models/targetdiff/TargetDiffJobs";
// import Properties from "../components/propertyfilter/Properties";
import PropertiesEvaluations from "../views/Properties/PropertiesEvaluations";
import { ProtectedRoute, PageWrapper } from "../components";
import NGLTestViewer from "../components/common/NGLTestViewer";
import ADMESubmitJobs from "../views/AdmeTox/ADMESubmitJobs";
import ADMEEvaluations from "../views/AdmeTox/ADMEEvaluations";
import DockingHome from "../views/Docking/DockingHome";
import PropertiesV2 from "../views/Properties/PropertiesV2";
import DockingClient from "../views/Docking/DockingClient";
import MolViewerHome from "../views/DrViz/MolViewerHome";
import LigandInteractionTable from "../components/protein-ligand/LigandInteractionTable";
import ADMEPropertyFilter from "../views/AdmeTox/ADMEPropertyFilter";
import DockingResults from "../views/Docking/DockingResults";
import PromiscuitySubmitJobs from "../views/Promiscuity/PromiscuitySubmitJobs";
import GenieDashboard from "../views/Genie/GenieDashboard";
import PromiscuityVisualize from "../views/Promiscuity/PromiscuityVisualize";
import PromiscuityViewer from "../views/Promiscuity/PromiscuityViewer";
import TagMolChemlet from "../views/Chemlets/TagMolChemlet";
import RGroupsChemlet from "../views/Chemlets/RGroupsChemlet";
import ChemletDashboard from "../views/Chemlets/ChemletDashboard";
import SequentialFiltering from "../views/Chemlets/SequentialFiltering";
// import SheetsSample from "../views/Chemlets/SheetsSample";
import Fragments from "../views/Chemlets/Fragments";
import MolSimilarity from "../views/Chemlets/MolSimilarity";
import DeepPocket from "../views/Chemlets/DeepPocket";
import PLIP from "../views/Chemlets/PLIP";
import DeepDTA from "../views/Chemlets/DeepDTA";
import AutoFragDiff from "../views/Chemlets/AutoFragDiff";
import MolProperties from "../views/Chemlets/MolProperties";
import MolEditor from "../views/Chemlets/MolEditor";
import ProfilePage from "../views/ProfilePage/ProfilePage";
import Pricing from "../views/Pricing/pricing";
import PropertiesHomeV3 from "../views/Properties/PropertiesHomeV3";
import PropertiesVisualizeV3 from "../views/Properties/PropertiesVisualizeV3";
import LeaderDashboard from "../views/Leader/LeaderDashboard";
// import InteractionAdderHome from "../views/InteractionAdder/InteractionAdderHome";
import MoleculeCopilotHome from "../views/MoleculeCopilot/MoleculeCopilotHome";
import Molfil from "../views/Molfil/Molfil";
import LiftDashboard from "../views/LIFT/LiftDashboard";
import LiftVisualizer from "../views/LiftVisualizer/LiftVisualizer";
import LiftVisualResult from "../views/LiftVisualizer/LiftVisualResult";
import LiftFilterMolecules from "../views/LiftFilter/LiftFilterMolecules";
import LiftFilterVisualizer from "../views/LiftFilter/LiftFilterVisualizer";
import Bioisosteric from "../views/Bioisosteric/Bioisosteric";
import MoleculeCopilot from "../views/MoleculeCopilot/MoleculeCopilot";
import MoleculeCopilotResult from "../views/MoleculeCopilot/MoleculeCopilotResult";
// import Comingsoon from "../views/ComingSoon/Comingsoon";
import RankerVisualize from "../views/Ranker/RankerVisualize";
import RankerSubmitJobs from "../views/Ranker/RankerSubmitJobs";
import RankerEvaluations from "../views/Ranker/RankerEvaluations";
import LeaderReinvent from "../views/leaderReinvent/LeaderReinvent";
import LeaderReinventVisualize from "../views/leaderReinvent/LeaderReinventVisualize";

type MoonvizRoutes = RouteObject & {
  title?: string;
  children?: Array<RouteObject & MoonvizRoutes>;
};
export const routes: Array<MoonvizRoutes> = [
  {
    path: "/",
    element: (
      <ProtectedRoute section="Dashboard">
        <Dashboard />
      </ProtectedRoute>
    ),
    title: "Dashboard",
    id: "home",
  },

  {
    path: "virtual-screening",
    element: (
      <ProtectedRoute section="Virtual Screening">
        <DockingDashboard />
      </ProtectedRoute>
    ),
    title: "Virtual Screening",
    id: "virtual-screening-dashboard",
  },

  // Docking Viewer
  {
    path: "virtual-screening/docking/viewer",
    element: (
      <ProtectedRoute section="Virtual Screening">
        <DockingHome />
      </ProtectedRoute>
    ),
    title: "Docking Viewer",
    id: "docking-viewer",
  },

  {
    path: "ires",
    element: (
      <ProtectedRoute section="Dashboard">
        <NGLTestViewer />
      </ProtectedRoute>
    ),
    title: "Interface Residue",
    id: "ires",
  },

  // DiffMol
  {
    path: "models/targetdiff/submit",
    element: (
      <ProtectedRoute section="Dashboard">
        <TargetDiffSubmitJob />
      </ProtectedRoute>
    ),
    title: "Submit DiffMol Job",
    id: "tdiffsubmit",
  },
  {
    path: "models/targetdiff/job/:jobID",
    element: (
      <ProtectedRoute section="Dashboard">
        <TargetDiffJobs />
      </ProtectedRoute>
    ),
    title: "DiffMol Jobs",
    id: "tdiffjobs",
  },

  {
    path: "models/targetdiff/*",
    element: (
      <ProtectedRoute section="Dashboard">
        <TargetDiff />
      </ProtectedRoute>
    ),
    title: "DiffMol",
    id: "tdiff",
  },

  {
    path: "genie",
    element: (
      <ProtectedRoute section="De Novo Generation">
        <GenieDashboard />
      </ProtectedRoute>
    ),
    title: "De Novo Generation Dashboard",
    id: "genie-dashboard",
  },
  // {
  //   path: "genie/targetdiff",
  //   element: (
  //     <ProtectedRoute section="De Novo Generation">
  //       <DiffMolHome />
  //     </ProtectedRoute>
  //   ),
  //   title: "DiffMol Genie",
  //   id: "targetdiff",
  // },
  {
    path: "genie/maimol",
    element: (
      <ProtectedRoute section="De Novo Generation">
        <DiffMolHome />
      </ProtectedRoute>
    ),
    title: "MAIMol",
    id: "maimol",
  },
  {
    path: "genie/ranker",
    element: (
      <ProtectedRoute section="De Novo Generation">
        <RankerSubmitJobs />
      </ProtectedRoute>
    ),
    title: "Ranker",
    id: "ranker",
  },
  {
    path: "genie/ranker/visualize",
    element: (
      <ProtectedRoute section="De Novo Generation">
        <RankerVisualize />
      </ProtectedRoute>
    ),
    title: "Ranker",
  },
  {
    path: "genie/ranker/evaluations",
    element: (
      <ProtectedRoute section="De Novo Generation">
        <RankerEvaluations />
      </ProtectedRoute>
    ),
    title: "Ranker",
  },
  {
    path: "models/dymol",
    element: (
      <ProtectedRoute section="Dashboard">
        <DyMean />
      </ProtectedRoute>
    ),
    title: "DyMol",
    id: "dymol",
  },

  // {
  //   path: "docking/diffdock",
  //   element: (
  //     <ProtectedRoute section="Virtual Screening">
  //       <JobSubmit />
  //     </ProtectedRoute>
  //   ),
  //   title: "DiffDock Docking",
  //   id: "diffdock",
  // },

  {
    path: "adme",
    element: (
      <ProtectedRoute section="ADMET">
        <ADMESubmitJobs />
      </ProtectedRoute>
    ),
    title: "ADME and Tox",
    id: "adme_tox",
  },
  {
    path: "adme/evaluations",
    element: (
      <ProtectedRoute section="ADMET">
        <ADMEEvaluations />
      </ProtectedRoute>
    ),
    title: "ADMET Evaluation Results",
    id: "adme_tox_eval",
  },
  {
    path: "adme/visualize",
    element: (
      <ProtectedRoute section="ADMET">
        <ADMEPropertyFilter />
      </ProtectedRoute>
    ),
    title: "ADME and Tox",
  },
  {
    path: "virtual-screening/properties",
    element: (
      <ProtectedRoute section="Virtual Screening">
        <PropertiesHome />
      </ProtectedRoute>
    ),
    title: "Screenie",
    id: "properties-home",
  },
  {
    path: "virtual-screening/properties-v2",
    element: (
      <ProtectedRoute section="Virtual Screening">
        <PropertiesHome />
      </ProtectedRoute>
    ),
    title: "Screenie",
    id: "properties-home-v2",
  },
  {
    path: "virtual-screening/properties-v3",
    element: (
      <ProtectedRoute section="Virtual Screening">
        <PropertiesHomeV3 />
      </ProtectedRoute>
    ),
    title: "Screenie",
    id: "properties-home-v3",
  },
  {
    path: "virtual-screening/properties-v3/visualize",
    element: (
      <ProtectedRoute section="Virtual Screening">
        <PropertiesVisualizeV3 />
      </ProtectedRoute>
    ),
    title: "Screenie",
    id: "properties-visualize-v3",
  },
  {
    path: "virtual-screening/properties/filter",
    element: (
      <ProtectedRoute section="Virtual Screening">
        <Properties />
      </ProtectedRoute>
    ),
    title: "Screenie",
    id: "properties-filter",
  },
  {
    path: "/user/profile",
    element: <ProfilePage />,
    title: "Profile",
    id: "profile",
  },

  {
    path: "virtual-screening/properties-v2/filter",
    element: (
      <ProtectedRoute section="Coming Soon">
        <PropertiesV2 />
      </ProtectedRoute>
    ),
    title: "Screenie",
    id: "properties-filter-v2",
  },

  {
    path: "pricing",
    element: <Pricing />,
    title: "Pricing",
    id: "pricing",
  },

  {
    path: "user/login",
    element: (
      // <ProtectedRoute  >
      <Login />
      // </ProtectedRoute>
    ),
    title: "Login",
    id: "login",
  },
  {
    path: "user/signup",
    element: (
      // <ProtectedRoute >
      <Signup />
      // </ProtectedRoute>
    ),
    title: "SignUp",
    id: "signup",
  },

  // Docking client
  {
    path: "virtual-screening/autodock",
    element: (
      <ProtectedRoute section="Virtual Screening">
        <DockingClient />
      </ProtectedRoute>
    ),

    title: "AutoDock Docking",
    id: "docking-autodock",
  },
  {
    path: "virtual-screening/unidock",
    element: (
      <ProtectedRoute section="Virtual Screening">
        <DockingClient />
      </ProtectedRoute>
    ),

    title: "UniDock Docking",
    id: "docking-unidock",
  },
  {
    path: "virtual-screening/autodock/visualize",
    element: (
      <ProtectedRoute section="Virtual Screening">
        <AutoDockVisualize />
      </ProtectedRoute>
    ),

    title: "Autodock Visualization",
    id: "docking-autodock-visualize",
  },
  {
    path: "virtual-screening/unidock/visualize",
    element: (
      <ProtectedRoute section="Virtual Screening">
        <AutoDockVisualize />
      </ProtectedRoute>
    ),

    title: "UniDock Visualization",
    id: "docking-unidock-visualize",
  },
  {
    path: "virtual-screening/autodock/viewer",
    element: (
      <ProtectedRoute section="Virtual Screening">
        <AutoDockViewer />
      </ProtectedRoute>
    ),

    title: "Autodock Viewer",
    id: "docking-autodock-viewer",
  },
  {
    path: "virtual-screening/unidock/viewer",
    element: (
      <ProtectedRoute section="Virtual Screening">
        <AutoDockViewer />
      </ProtectedRoute>
    ),

    title: "UniDock Viewer",
    id: "docking-unidock-viewer",
  },
  {
    path: "virtual-screening/autodock/results",
    element: (
      <ProtectedRoute section="Virtual Screening">
        <DockingResults />
      </ProtectedRoute>
    ),

    title: "AutoDock Results",
    id: "results-autodock",
  },
  {
    path: "virtual-screening/unidock/results",
    element: (
      <ProtectedRoute section="Virtual Screening">
        <DockingResults />
      </ProtectedRoute>
    ),

    title: "UniDock Results",
    id: "results-unidock",
  },
  {
    path: "ligand-interaction",
    element: (
      <ProtectedRoute section="Dashboard">
        <LigandInteractionTable />
      </ProtectedRoute>
    ),

    title: "Protein Ligand Interaction",
    id: "ligand-interaction",
  },
  {
    path: "virtual-screening/properties-v3/evaluations",
    element: (
      <ProtectedRoute section="Virtual Screening">
        <PropertiesEvaluations />
      </ProtectedRoute>
    ),
    title: "PhysChem Filter Evaluation",
    id: "Properties Evaluation",
  },
  {
    path: "virtual-screening/molfil",
    element: (
      <ProtectedRoute section="Virtual Screening">
        <Molfil />
      </ProtectedRoute>
    ),
    title: "MolFil",
    id: "molfil",
  },
  {
    path: "virtual-screening/lift/visualizer",
    element: (
      <ProtectedRoute section="Virtual Screening">
        <LiftVisualizer />
      </ProtectedRoute>
    ),

    title: "LIFT Visualizer",
    id: "lift-visualizer",
  },

  {
    path: "virtual-screening/lift/visualizer/result",
    element: (
      <ProtectedRoute section="Virtual Screening">
        <LiftVisualResult />
      </ProtectedRoute>
    ),

    title: "LIFT Visualizer",
    id: "lift-visualizer-result",
  },
  {
    path: "virtual-screening/lift/filter-molecules",
    element: (
      <ProtectedRoute section="Virtual Screening">
        <LiftFilterMolecules />
      </ProtectedRoute>
    ),

    title: "LIFT Filter Molecules",
    id: "filter-molecules",
  },
  {
    path: "virtual-screening/lift/filter-molecules/visualize",
    element: (
      <ProtectedRoute section="Virtual Screening">
        <LiftFilterVisualizer />
      </ProtectedRoute>
    ),

    title: "LIFT Filter Molecules",
    id: "filter-molecules-visualize",
  },
  {
    path: "leader",
    element: (
      <ProtectedRoute section="Lead Optimisation">
        <LeaderDashboard />
      </ProtectedRoute>
    ),

    title: "Lead Optimisation",
    id: "leader",
  },
  // {
  //   path: "leader/interactions",
  //   element: (
  //     <ProtectedRoute section="Lead Optimisation">
  //        <InteractionAdderHome/>
  //     </ProtectedRoute>
  //   ),

  //   title: "Interaction Adder",
  //   id: "interaction-adder",
  // },
  {
    path: "leader/copilot",
    element: (
      <ProtectedRoute section="Lead Optimisation">
        <MoleculeCopilot />
      </ProtectedRoute>
    ),

    title: "Molecule CoPilot",
    id: "molecule-copilot",
  },
  {
    path: "leader/copilot/modifications",
    element: (
      <ProtectedRoute section="Lead Optimisation">
        <MoleculeCopilotResult />
      </ProtectedRoute>
    ),

    title: "Molecule CoPilot",
    id: "molecule-modifications",
  },

  {
    path: "leader/boisosteric",
    element: (
      <ProtectedRoute section="Lead Optimisation">
        <Bioisosteric />
      </ProtectedRoute>
    ),

    title: "Bioisosteric Replacement",
    id: "bioisosteric-replacement",
  },
  {
    path: "editor",
    element: (
      <ProtectedRoute section="Molecule Design">
        <MolEditor></MolEditor>
      </ProtectedRoute>
    ),

    title: "MolBench",
    id: "mol-editor",
  },
  {
    path: "utilities/copilot",
    element: (
      <ProtectedRoute section="Coming Soon">
        <MoleculeCopilotHome />
      </ProtectedRoute>
    ),

    title: "Molecule CoPilot",
    id: "utilities-molecule-copilot",
  },
  {
    path: "utilities/lift",
    element: (
      <ProtectedRoute section="LIFT">
        <PLIP></PLIP>
        {/* <LiftDashboard /> */}
      </ProtectedRoute>
    ),

    title: "LIFT",
    id: "LIFT",
  },
  {
    path: "leader/r-groups",
    element: (
      <ProtectedRoute section="R Groups">
        <RGroupsChemlet />
      </ProtectedRoute>
    ),

    title: "R Group Replacements App",
    id: "chemlet-rgroups",
  },
  {
    path: "utilities",
    element: (
      <ProtectedRoute section="Coming Soon">
        <ChemletDashboard />
      </ProtectedRoute>
    ),
    title: "Utilities Dashboard",
    id: "utilities-dashboard",
  },
  {
    path: "utilities/tagmol",
    element: (
      <ProtectedRoute section="Coming Soon">
        <TagMolChemlet />
      </ProtectedRoute>
    ),

    title: "TagMol Chemlet",
    id: "chemlet-tagmol",
  },

  {
    path: "utilities/seq-filtering",
    element: (
      <ProtectedRoute section="Coming Soon">
        <SequentialFiltering />
      </ProtectedRoute>
    ),

    title: "Sequential Ligand Filtering",
    id: "chemlet-seq-filtering",
  },
  // {
  //   path: "utilities/sheets",
  //   element: (
  //     <ProtectedRoute section="Coming Soon">
  //       <SheetsSample />
  //     </ProtectedRoute>
  //   ),

  //   title: "Example Sheets",
  //   id: "chemlet-sheet",
  // },
  {
    path: "utilities/fragments",
    element: (
      <ProtectedRoute section="Coming Soon">
        <Fragments />
      </ProtectedRoute>
    ),

    title: "Fragment-based Ligand Design",
    id: "fragments",
  },
  {
    path: "utilities/mol-similarity",
    element: (
      <ProtectedRoute section="Coming Soon">
        <MolSimilarity />
      </ProtectedRoute>
    ),

    title: "Molecular Similarity",
    id: "mol-similarity",
  },
  {
    path: "utilities/mol-props",
    element: (
      <ProtectedRoute section="Coming Soon">
        <MolProperties />
      </ProtectedRoute>
    ),

    title: "Molecular Properties",
    id: "mol-props",
  },
  {
    path: "utilities/deep-pocket",
    element: (
      <ProtectedRoute section="Coming Soon">
        <DeepPocket></DeepPocket>
      </ProtectedRoute>
    ),

    title: "Deep Pocket",
    id: "deep-pocket",
  },
  {
    path: "utilities/deep-dta",
    element: (
      <ProtectedRoute section="Coming Soon">
        <DeepDTA></DeepDTA>
      </ProtectedRoute>
    ),

    title: "DeepDTA",
    id: "deep-dta",
  },
  {
    path: "utilities/autofragdiff",
    element: (
      <ProtectedRoute section="Coming Soon">
        <AutoFragDiff></AutoFragDiff>
      </ProtectedRoute>
    ),

    title: "AutoFragDiff",
    id: "autofragdiff",
  },
  // Viewer
  {
    path: "utilities/mol-viewer",
    element: (
      <ProtectedRoute section="Coming Soon">
        <MolViewerHome />
      </ProtectedRoute>
    ),
    title: "Dr Viz",
    id: "mol-viewer",
  },
  {
    path: "utilities/mol-viewer/viewer",
    element: (
      <ProtectedRoute section="Coming Soon">
        <KViewer />
      </ProtectedRoute>
    ),
    title: "Dr Viz",
    id: "viewer",
  },
  {
    path: "utilities/pdb/prep",
    element: (
      <ProtectedRoute section="Coming Soon">
        <ProtienPreprocess />
      </ProtectedRoute>
    ),
    title: "Protein Prep",
    id: "protein-prep",
  },
  // Protien Info
  {
    path: "utilities/protein-info",
    element: (
      <ProtectedRoute section="Coming Soon">
        <PDBInfo />
      </ProtectedRoute>
    ),
    title: "Protein Info",
    id: "protein-info",
  },
  {
    path: "utilities/protein-info/details",
    element: (
      <ProtectedRoute section="Coming Soon">
        <PDBInfoDetails />
      </ProtectedRoute>
    ),
    title: "Protein Info",
    id: "protein-info-details",
  },
  // AutoMol
  //  {
  //   path: "utilities/models/automol",
  //   element: (
  //     <ProtectedRoute section="Coming Soon">
  //       <Limo />
  //     </ProtectedRoute>
  //   ),
  //   title: "Leader",
  //   id: "limo",
  // },
  {
    path: "utilities/genie/tagmol",
    element: (
      <ProtectedRoute section="Coming Soon">
        <DiffMolHome />
      </ProtectedRoute>
    ),
    title: "TagMOL Genie",
    id: "tagmol",
  },
  // Ligand
  {
    path: "utilities/ligand",
    element: (
      <ProtectedRoute section="Coming Soon">
        <LigandHome />
      </ProtectedRoute>
    ),
    title: "Ligand Prep",
    id: "ligand",
  },

  {
    path: "utilities/ligand/prep",
    element: (
      <ProtectedRoute section="Coming Soon">
        <LigandPrep />
      </ProtectedRoute>
    ),
    title: "Ligand Prep",
    id: "ligand-prep",
  },
  {
    path: "utilities/promiscuity",
    element: (
      <ProtectedRoute section="Promiscuity Filter">
        <PromiscuitySubmitJobs />
      </ProtectedRoute>
    ),
    title: "Promiscuity Filter",
    id: "Promiscuity",
  },
  {
    path: "utilities/promiscuity/visualize",
    element: (
      <ProtectedRoute section="Promiscuity Filter">
        <PromiscuityVisualize />
      </ProtectedRoute>
    ),

    title: "Promiscuity Filter Visualization",
    id: "promiscuity-visualize",
  },
  {
    path: "utilities/promiscuity/viewer",
    element: (
      <ProtectedRoute section="Promiscuity Filter">
        <PromiscuityViewer />
      </ProtectedRoute>
    ),

    title: "Promiscuity Filter Visualization",
    id: "promiscuity-viewer",
  },
  {
    path: "leader/leader",
    element: (
      <ProtectedRoute section="Lead Optimisation">
        <LeaderReinvent />
      </ProtectedRoute>
    ),
    title: "Leader",
    id: "leader-reinvent",
  },
  {
    path: "leader/leader/visualize",
    element: (
      <ProtectedRoute section="Lead Optimisation">
        <LeaderReinventVisualize />
      </ProtectedRoute>
    ),
    title: "Leader",
    id: "leader-visualize",
  },
  {
    path: "*",
    element: <PageNotFound />,
    title: "Page Not Found",
    id: "404",
  },
];

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    id: "root",
    children: routes.map(({ path, element, id, title, children }) => ({
      path,
      element: <PageWrapper title={title}>{element}</PageWrapper>,
      id,
      children: children ? [...children] : [],
    })),
  },
]);

export { router };
