import React from "react";
import { PropertiesInput, WorkflowLandingTabs } from "../../components";
import { WorkflowLandingTab } from "../../models";
import { Grid, Typography, Box, CardContent, Card } from "@mui/material";
import CopilotInput from "../../components/MoleculeCopilot/CopilotInput";
import IndefiniteLoader from "../../components/common/IndefiniteLoader";

const tabs: Array<WorkflowLandingTab> = [
  {
    id: "0",
    label: "Description",
    value: (
      <>
        <Typography paragraph>
          Copilot allows users to optimize molecules using an intuitive,
          interactive interface powered by natural language feedback. Users can
          input a molecule in the form of a SMILES (Simplified Molecular Input
          Line Entry System) string, along with a desired modification described
          in plain text. Copilot then processes this information to generate
          optimized molecules that meet the specified criteria. By leveraging
          sophisticated molecular modeling algorithms, the tool provides users
          with real-time feedback, enabling them to explore various molecular
          alterations quickly and efficiently.
        </Typography>
        <Box
          component="img"
          src="images/dashcard-images/molecule_copilot5.jpg"
          alt="Protein Info Visual"
          sx={{
            width: "60%", // Reduces the width to 80% of the parent container
            height: "auto",
            mt: 4, // Adds margin-top for space above the image
            mb: 2, // Adds margin-bottom for space below the image
            mx: "auto", // Centers the image horizontally
            display: "block", // Ensures the Box behaves like a block element
            p: 2, // Adds padding around the image inside the Box
          }}
        />
      </>
    ),
  },
];
function MoleculeCopilot() {
  return (
    // <Grid container spacing={2.5} px={3} mt={0.5}>
    //   <Grid item xs={8}>
    //     <WorkflowLandingTabs tabs={tabs} />
    //   </Grid>
    //   <Grid item xs={4}>
    //     <CopilotInput  />
    //   </Grid>
    // </Grid>
    <>
      <Grid container py={1} px={3} spacing={2}  >
        <Grid item xs={12} md={8} sx={{ pt: 2, width: "100%" }} pl={3}  display={"flex"}>
          <WorkflowLandingTabs tabs={tabs} />
        </Grid>

        <Grid item xs={12} md={4}  sx={{ width: "100%" }} display={"flex"}>
          <CopilotInput />
        </Grid>

        {/* //   {/* Examples */}
      </Grid>
    </>
  );
}

export default MoleculeCopilot;
