import { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  DataGridPro,
  GridColDef,
  useGridApiRef,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
} from "@mui/x-data-grid-pro";
import { Box, Button, Card, Grid, Tab, ToggleButtonGroup } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import { TabPanel } from "../../components/common/TabPanel";
import { tableStateType } from "../AdmeTox/ADMEPropertyFilter";
import WindowIcon from "@mui/icons-material/Window";
import ScreenieGridView from "../../components/Properties/ScreenieGridView";
import IndefiniteLoader from "../../components/common/IndefiniteLoader";

export const CustomToolbar = ({
  props,
  isExport = true,
}: {
  props: any;
  isExport?: boolean;
}) => {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      {isExport && (
        <GridToolbarExport
          printOptions={{
            disableToolbarButton: true,
          }}
        />
      )}
    </GridToolbarContainer>
  );
};

const LeaderReinventVisualize = () => {
  const [tabValue, setTabValue] = useState("1");
  const [alignment, setAlignment] = useState("grid");
  const scrollToRef = useRef(null);
  const [rows, setRows] = useState<any[]>([]);
  const [moleculeFilter, setMoleculeFilter] = useState<any>(null);
  const [densityAndRows, setDensityAndRows] = useState<tableStateType>({
    density: "standard",
    pageSize: 5,
  });

  const navigate = useNavigate();

  const handleScroll = () => {
    if (scrollToRef.current) {
      scrollToRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleTableStateChange = (
    newState: any,
    setFilterState: React.Dispatch<any>
  ) => {
    setFilterState(newState.filter.filterModel);
  };

  const leaderReinvent = useSelector(
    (state: any) => state.leaderReinventProperty.properties
  );

  let table = useGridApiRef();

  const cols: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      type: "number",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "SMILES",
      headerName: "SMILES",
      editable: false,
      headerAlign: "center",
      width: 200,
    },
    {
      field: "svg",
      headerName: "2D Representation",
      description: "This column has images and is not sortable.",
      sortable: false,
      filterable: false,
      disableExport: true,
      width: 140,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <img
          src={`data:image/svg+xml;base64,${btoa(params.value)}`}
          alt="2D svg representation"
          style={{
            height: "200px",
            width: "80%",
            transition: "transform 0.3s ease-in-out",
          }}
          onMouseOver={(e) => {
            e.currentTarget.style.transform = "scale(150%)";
          }}
          onMouseOut={(e) => {
            e.currentTarget.style.transform = "scale(100%)";
          }}
        />
      ),
    },
    {
      field: "Input_SMILES",
      headerName: "Input SMILES",
      editable: false,
      headerAlign: "center",
      width: 200,
    },
    {
      field: "Tanimoto",
      headerName: "Tanimoto",
      type: "number",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "Gen-SA-Score",
      headerName: "Gen SA Score",
      type: "number",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "Inp-SA-Score",
      headerName: "Input SA Score",
      type: "number",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "Gen-QED",
      headerName: "Gen QED",
      type: "number",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "Inp-QED",
      headerName: "Input QED",
      type: "number",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "Gen-HIA_HOU",
      headerName: "Gen Human Int. Absorption",
      type: "number",
      headerAlign: "center",
      align: "center",
      valueGetter: (params) =>
        params.row["Gen-HIA_HOU"] || params.row["Gen-Human-Int.-Absorption"],
    },
    {
      field: "Gen-VDSS",
      headerName: "Gen VDSS",
      type: "number",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "Gen-Half-Life",
      headerName: "Gen Half-Life",
      type: "number",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "Gen-Hepatotoxicity",
      headerName: "Gen Hepatotoxicity",
      type: "number",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "Gen-Cardiotoxicity",
      headerName: "Gen Cardiotoxicity",
      type: "number",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "Inp-HIA_HOU",
      headerName: "Input Human Int. Absorption",
      type: "number",
      headerAlign: "center",
      align: "center",
      valueGetter: (params) =>
        params.row["Inp-HIA_HOU"] || params.row["Inp-Human-Int.-Absorption"],
    },
    {
      field: "Inp-VDSS",
      headerName: "Input VDSS",
      type: "number",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "Inp-Half-Life",
      headerName: "Input Half-Life",
      type: "number",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "Inp-Hepatotoxicity",
      headerName: "Input Hepatotoxicity",
      type: "number",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "Inp-Cardiotoxicity",
      headerName: "Input Cardiotoxicity",
      type: "number",
      headerAlign: "center",
      align: "center",
    },
  ];

  const fieldsToRound = [
    "Gen-Cardiotoxicity",
    "Gen-HIA_HOU",
    "Gen-Half-Life",
    "Gen-Hepatotoxicity",
    "Gen-QED",
    "Gen-SA-Score",
    "Gen-VDSS",
    "Inp-Cardiotoxicity",
    "Tanimoto",
    "Inp-HIA_HOU",
    "Inp-Half-Life",
    "Inp-Hepatotoxicity",
    "Inp-QED",
    "Inp-SA-Score",
    "Inp-VDSS",
    "Inp-Human-Int.-Absorption",
    "Gen-Human-Int.-Absorption",
  ];

  useEffect(() => {
    if (Array.isArray(leaderReinvent?.data)) {
      const modifiedRows = leaderReinvent.data.map((row: any) => {
        let newRow = { ...row };
        fieldsToRound.forEach((field) => {
          if (newRow[field]) {
            newRow[field] = newRow[field].toFixed(3);
          }
        });
        return newRow;
      });
      setRows(modifiedRows);
    } else if (leaderReinvent.data === null && !leaderReinvent.loading) {
      navigate("/leader/leader");
    }
  }, [leaderReinvent]);

  return (
    <>
      <ToggleButtonGroup
        color="primary"
        value={alignment}
        exclusive
        aria-label="Platform"
        sx={{
          margin: "-1rem 1rem 0rem 3rem",
          display: "flex",
          justifyContent: "right",
        }}
      >
        <Button
          sx={{
            fontSize: 12,
            paddingTop: 1,
            paddingBottom: 1,
            mx: 1,
            backgroundColor: "#582FF1;",
            fontWeight: "bold",
          }}
          onClick={handleScroll}
          variant="contained"
          startIcon={<WindowIcon sx={{ fontSize: 12 }} />}
          size="large"
        >
          Grid View
        </Button>
      </ToggleButtonGroup>
      <Grid container spacing={2} p={2}>
        <Grid item container px={1} pt={3} height={"100%"}>
          <Grid item width={"100%"} sx={{ overflowY: "auto" }} height={"100%"}>
            <Card sx={{ position: "relative" }}>
              <TabContext value={tabValue}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList
                    value={tabValue}
                    aria-label="filter tabs"
                    variant="scrollable"
                    scrollButtons="auto"
                  >
                    <Tab label="Molecules" value="1" />
                  </TabList>
                </Box>
                <TabPanel value="1">
                  <Box sx={{ width: "100%", height: "700px" }}>
                    <DataGridPro
                      apiRef={table}
                      sx={{
                        "& .MuiDataGrid-columnHeaderTitle": {
                          whiteSpace: "normal",
                          lineHeight: "normal",
                          fontWeight: "550",
                          fontSize: "0.9rem",
                        },
                        "& .MuiDataGrid-columnHeader": {
                          // Forced to use important since overriding inline styles
                          height: "unset !important",
                        },
                        "& .MuiDataGrid-columnHeaders": {
                          // Forced to use important since overriding inline styles
                          maxHeight: "175px !important",
                          textAlign: "center",
                          fontWeight: "800 !important",
                        },
                      }}
                      rows={rows}
                      columns={cols}
                      disableRowSelectionOnClick
                      onStateChange={(newState) =>
                        handleTableStateChange(newState, setMoleculeFilter)
                      }
                      rowHeight={100}
                      pagination
                      density={
                        densityAndRows.density
                          ? densityAndRows.density
                          : "standard"
                      }
                      initialState={{
                        pagination: {
                          paginationModel: {
                            pageSize: densityAndRows.pageSize
                              ? densityAndRows.pageSize
                              : 5,
                          },
                        },
                      }}
                      pageSizeOptions={[5, 10, 20]}
                      // autoHeight
                      slots={{
                        toolbar: (props) => <CustomToolbar {...props} />,
                      }}
                    />
                  </Box>
                </TabPanel>
              </TabContext>
            </Card>
          </Grid>
        </Grid>
      </Grid>

      <Box ref={scrollToRef} pb={2}>
        <ScreenieGridView
          isTopLevelEnable={false}
          tabValue="1"
          allRows={rows}
          filters={[moleculeFilter]}
          model="leader_reinvent"
        />
      </Box>
      <IndefiniteLoader state={leaderReinvent.loading} />
    </>
  );
};

export default LeaderReinventVisualize;
