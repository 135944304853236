
import { CustomizeToxicFilters } from "./screenieTypes"

class InitialData{
    private customizeToxicInitialData:CustomizeToxicFilters[]=[
        {
          type: "BRENK",
          action: ["SELECT_ALL", "IGNORE_ALL", "SELECT_FEW", "IGNORE_FEW"],
          subrules: [],
        },
        {
          type: "NIH",
          action: ["SELECT_ALL", "IGNORE_ALL", "SELECT_FEW", "IGNORE_FEW"],
          subrules: [],
        },
        {
          type: "PAINS_A",
          action: ["SELECT_ALL", "IGNORE_ALL", "SELECT_FEW", "IGNORE_FEW"],
          subrules: [],
        },
        {
          type: "PAINS_B",
          action: ["SELECT_ALL", "IGNORE_ALL", "SELECT_FEW", "IGNORE_FEW"],
          subrules: [],
        },
        {
          type: "PAINS_C",
          action: ["SELECT_ALL", "IGNORE_ALL", "SELECT_FEW", "IGNORE_FEW"],
          subrules: [],
        },
        {
          type: "ZINC",
          action: ["SELECT_ALL", "IGNORE_ALL", "SELECT_FEW", "IGNORE_FEW"],
          subrules: [],
        },
      ]



      // Method to get all types or data
  public getCustomizedData(): CustomizeToxicFilters[] {
    return this.customizeToxicInitialData;
  }

}

// Export the class to use in TSX files
export default InitialData;