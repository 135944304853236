import {
  Box,
  Button,
  CardActions,
  CardContent,
  Stack,
  Typography,
  Alert,
  Snackbar,
  Grid,
  FormControl,
  LinearProgress,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";

import { JOB_SERVER_URL, RUNTIME, SERVER_URL } from "../../config";

import http from "../../net/http-common";

import IndefiniteLoader from "../../components/common/IndefiniteLoader";
import { FileUploader } from "../../components";
import { errorMessages } from "../../common_variables/ErrorMsgs";
import { useUserAuth } from "../../context";

function LiftJobInput({
  setJobID,
  SetShowSubmitMsg,
  setDoLoadJobs,
}: {
  setJobID: React.Dispatch<React.SetStateAction<number>>;
  SetShowSubmitMsg: React.Dispatch<React.SetStateAction<boolean>>;
  setDoLoadJobs: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const [errorMsg, setErrorMsg] = useState(null);
  const [showError, setShowError] = useState(false);
  const [PDBFile, setPDBFile] = useState<File>(null);
  const [ligandsFile, setLigandsFile] = useState<File>(null);
  const [pdbId, setPdbId] = useState<string>("");
  const [inProgress, setInProgress] = useState<boolean>(false);
  const [fetchingFields, setfetchingFields] = useState(false);
  const [chains, setChains] = useState<string[]>([]);
  const [residues, setResidues] = useState<string[]>([]);
  const [residue, setResidue] = useState("");
  const [protChain, setProtChain] = useState("");
  const [ligandColName, setLigandColName] = useState("smiles");
  const [ligandSmiles, setLigandSmiles] = useState("");
  const { user } = useUserAuth();

  const handleFileUpload = async (file: File, name: string) => {
    switch (name) {
      case "pdb":
        setPDBFile(file);
        break;
      case "csv":
        setLigandsFile(file);
        break;
      default:
        //console.log("file type unknown");
        break;
    }
  };

  const handleSubmit = async (e: any) => {
    setInProgress(true);

    const form = new FormData();
    form.append("uid", user.uid);

    form.append("residue_name", residue);
    form.append("protein_chain", protChain);

    form.append("lig_col_name", ligandColName);
    form.append("protein_file", PDBFile);

    try {
      if (ligandsFile) {
        form.append("ligands_file", ligandsFile);
        const response = await http.post(
          `${JOB_SERVER_URL}/lift/submit`,
          form,
          {
            headers: {
              accept: "application/json",
              "Content-Type": "multipart/form-data",
              "Access-Control-Allow-Origin": "http://localhost:3000",
            },
          }
        );

        setJobID(response.data.task_id);
        SetShowSubmitMsg(true);
        setDoLoadJobs(true);
      } else if (ligandSmiles) {
      }
    } catch (error) {
      setShowError(true);
      setErrorMsg(errorMessages.submitJobError);
    }

    setInProgress(false);
  };

  useEffect(() => {
    if (!PDBFile) return;

    const form = new FormData();
    form.append("uploaded_protein_file", PDBFile);

    setfetchingFields(true);

    http
      .post(`${SERVER_URL}/pdb/chains`, form, {
        headers: {
          accept: "application/json",
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "http://localhost:3000",
        },
      })
      .then((response: any) => {
        setfetchingFields(false);
        //  console.log(response);
        setChains(response.data[0]);
        setResidues(response.data[1]);
        setProtChain(response.data[0][0]);
        setResidue(response.data[1][0]);
      })
      .catch((error) => {
        setfetchingFields(false);
      });
  }, [PDBFile]);

  const handleDownloadSample = (fileType: string) => {
    const link = document.createElement("a");

    if (fileType == "protein") {
      link.download = "lift_filter_protein_sample.pdb";
      link.href = "/samples/autodock/2p16.pdb";
    } else if (fileType == "ligand") {
      link.download = "lift_filter_ligand_sample.csv";
      link.href = "/samples/screenie/input_screenie.csv";
    }

    link.click();
  };

  return (
    <>
      <Snackbar
        open={showError}
        autoHideDuration={9000}
        sx={{ width: "50%" }}
        onClose={() => {
          setShowError(false);
        }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => {
            setShowError(false);
          }}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {errorMsg}
        </Alert>
      </Snackbar>
      <Box sx={{ height: 1, width: "100%" }}>
        <Grid
          container
          className={RUNTIME === "PROD" ? "blurry-bg" : ""}
          display="flex"
          flexDirection="column"
        >
          <CardContent>
            <FormControl
              fullWidth
              className={RUNTIME === "PROD" ? "blurry-bg" : ""}
            >
              <Stack direction="column" spacing={1.5}>
                <Box display={"flex"} justifyContent={"space-between"}>
                  <Typography
                    sx={{
                      mt: 1,
                      color: "var(--shade-2900, #29283B)",
                      fontSize: "1rem",
                      fontWeight: "bold",
                    }}
                  >
                    Protein File (.pdb)
                  </Typography>
                  <Button
                    variant="text"
                    onClick={() => handleDownloadSample("protein")}
                  >
                    {"Download Sample"}
                  </Button>
                </Box>
                <FileUploader
                  accept={".pdb"}
                  handleFileUpload={(files) =>
                    handleFileUpload(files[0], "pdb")
                  }
                  deleteHandlerDisable={() => {
                    return false;
                  }}
                />
              </Stack>
              <Stack direction="column" spacing={1.5}>
                <FormControl>
                  <Typography
                    sx={{
                      mt: 1,
                      color: "var(--shade-2900, #29283B)",
                      fontSize: "1rem",
                      fontWeight: "bold",
                    }}
                  >
                    Residue Name
                  </Typography>
                  {fetchingFields ? <LinearProgress /> : ""}
                  <Select
                    disabled={residues?.length === 0}
                    value={residue}
                    label="Residue"
                    onChange={(e) => setResidue(e.target.value)}
                  >
                    {residues?.map((v, i) => {
                      return (
                        <MenuItem key={i} value={v}>
                          {v}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Stack>
              <Stack direction="column" spacing={1.5}>
                <FormControl>
                  <Typography
                    sx={{
                      mt: 1,
                      color: "var(--shade-2900, #29283B)",
                      fontSize: "1rem",
                      fontWeight: "bold",
                    }}
                  >
                    Protein Chain
                  </Typography>
                  {fetchingFields ? <LinearProgress /> : ""}
                  <Select
                    disabled={chains?.length === 0}
                    value={protChain}
                    label="Protein Chain"
                    onChange={(e) => setProtChain(e.target.value)}
                  >
                    {chains?.map((v, i) => {
                      return (
                        <MenuItem key={i} value={v}>
                          {v}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Stack>

              <Stack direction="column" spacing={1.5} mt={1} p={2}>
                <Box display={"flex"} justifyContent={"space-between"}>
                  <Typography
                    sx={{
                      mt: 1,
                      color: "var(--shade-2900, #29283B)",
                      fontSize: "1rem",
                      fontWeight: "bold",
                    }}
                  >
                    Ligands File (.csv)
                  </Typography>
                  <Button
                    variant="text"
                    onClick={() => handleDownloadSample("ligand")}
                  >
                    {"Download Sample"}
                  </Button>
                </Box>
                <FileUploader
                  accept={".csv"}
                  handleFileUpload={(files) =>
                    handleFileUpload(files[0], "csv")
                  }
                  deleteHandlerDisable={() => {
                    setLigandsFile(null);
                    return false;
                  }}
                  headerSelector={true}
                  handleSelectedHeader={setLigandColName}
                />
              </Stack>

              <Button
                variant="contained"
                onClick={handleSubmit}
                disabled={!PDBFile || !ligandsFile || !residue || !protChain}
                sx={{
                  mt: 2,
                  "&.Mui-disabled": {
                    background: "#BDADF9",
                    color: "#fff",
                  },
                }}
              >
                Submit
              </Button>
            </FormControl>
          </CardContent>
        </Grid>
        <IndefiniteLoader state={inProgress} />
      </Box>
    </>
  );
}

export default LiftJobInput;
