import {
    Grid,
    Box,
    FormControl,
    Button,
    Card,
    CardContent,
    LinearProgress,
    Stack,
    Typography,
    Alert,
    TextField,
    Snackbar,
} from "@mui/material";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { FileUploader, JobsTable, WorkflowLandingTabs } from "../../components";
import http from "../../net/http-common";
import Loaders from "../../components/common/Loaders";
import { JOB_SERVER_URL } from "../../config";
import { errorMessages } from "../../common_variables/ErrorMsgs";
import IndefiniteLoader from "../../components/common/IndefiniteLoader";
import { useUserAuth } from "../../context";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { fetchSingleSmileData, leaderReinventProperties } from "./leaderReinventSlice";
import { WorkflowLandingTab } from "../../models";


const LeaderReinvent = () => {

    const MODEL_NAME = "reinvent";
    const [doLoadJobs, setDoLoadJobs] = useState(true);
    const [jobID, setJobID] = useState(-1);
    const [showSubmitMsg, SetShowSubmitMsg] = useState(false);
    const [rows, setRows] = useState<any[]>();
    const [SMILESFile, setSMILESFile] = useState<File>(null);
    const [smiles, setSmiles] = useState("");
    const [headerName, setHeaderName] = useState("");
    const [inProgress, setInProgress] = useState(false);
    const state = useLocation();

    const [errorMsg, setErrorMsg] = useState(null);
    const [showError, setShowError] = useState(false);

    const globalDispatch = useAppDispatch();
    const { properties } = useAppSelector(leaderReinventProperties);
    const singleSmilejobStatus = properties.loading;
    const { user } = useUserAuth();

    const tabs: Array<WorkflowLandingTab> = [
        {
            id: "0",
            label: "Description",
            value: (
                <>
                    <Typography paragraph>
                        The ADME and Tox app harnesses the power of Graph Neural Networks
                        (GNNs) to predict the Absorption, Distribution, Metabolism, Excretion,
                        and Toxicity (ADMET) properties of molecules. It uses the
                        state-of-the-art GNN architectures, with over 200 computationally
                        derived molecular features as inputs to the network that provides
                        unparalleled accuracy.
                    </Typography>
                    <Box
                        component="img"
                        src="/images/hero/ADME2.png"
                        alt="Protein Info Visual"
                        sx={{
                            width: "60%",
                            height: "auto",
                            mt: 0, // Adds margin-top for space above the image
                            mb: 2, // Adds margin-bottom for space below the image
                            mx: "auto", // Centers the image horizontally
                            display: "block", // Ensures the Box behaves like a block element
                            p: 2, // Adds padding around the image inside the Box
                        }}
                    />
                </>
            ),
        },
    ]

    const handleFileUpload = async (file: File, name: string) => {
        switch (name) {
            case "smiles":
                setSMILESFile(file);
                break;
            default:
                //console.log("file type unknown");
                break;
        }
    };

    const handleDownloadSample = () => {
        const link = document.createElement("a");
        link.download = "reinvent_input.csv";
        link.href = "/samples/reinvent/reinvent.csv";
        link.click();
    };

    useEffect(() => {
        if (!doLoadJobs || !user) return;
        if (state.state?.smilename) {
            setSmiles(state.state.smilename);
            globalDispatch(
                fetchSingleSmileData({ smileName: state.state?.smilename })
            );
            return;
        }
        http
            .get(`${JOB_SERVER_URL}/userjobs`, {
                params: {
                    user_id: user.uid,
                    model_name: MODEL_NAME,
                    start: "0",
                    end: "10",
                },
                headers: {
                    accept: "application/json",
                },
            })
            .then((res) => {
                setDoLoadJobs(false);
                setRows(res.data);
            })
            .catch((error) => {
                setShowError(true);
                setErrorMsg(errorMessages.jobTableError);
                setDoLoadJobs(false);
                setRows([]);
            });
    }, [doLoadJobs]);

    const handleSubmit = async (e: any) => {
        if (smiles) {
            const smileName = smiles;
            globalDispatch(fetchSingleSmileData({ smileName }));
            return;
        }
        setInProgress(true);
        const data = new FormData();
        const jobServerUrl = `${JOB_SERVER_URL}/reinvent/submit`;
        data.append("uid", user.uid);
        data.append("ligand_csv_file", SMILESFile);

        http
            .post(jobServerUrl, data, {
                headers: {
                    accept: "application/json",
                    "Content-Type": "multipart/form-data",
                    "Access-Control-Allow-Origin": "http://localhost:3000",
                },
                params: {
                    ligand_col_name: headerName
                },
            })
            .then((response: any) => {
                setJobID(response.data.task_id);
                setInProgress(false);
                setDoLoadJobs(true);
                SetShowSubmitMsg(true);
            })
            .catch((error) => {
                setInProgress(false);
                setShowError(true);
                setErrorMsg(errorMessages.submitJobError);
            });
    }


    return (
        <Box py={2}>
            <Snackbar
                open={showError}
                autoHideDuration={9000}
                sx={{ width: "50%" }}
                onClose={() => {
                    setShowError(false);
                }}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
                <Alert
                    onClose={() => {
                        setShowError(false);
                    }}
                    severity="error"
                    variant="filled"
                    sx={{ width: "100%" }}
                >
                    {errorMsg}
                </Alert>
            </Snackbar>
            <Grid container px={3} spacing={2} alignItems="stretch">
                <Grid item sm={12} md={8} sx={{ width: "100%" }} display={"flex"}>
                    <WorkflowLandingTabs tabs={tabs} />
                </Grid>
                <Grid item sm={12} md={4} sx={{ width: "100%" }} display={"flex"}>
                    <Card sx={{ height: 1, width: "100%" }}>
                        <CardContent>
                            <Typography variant="h5" my={1}>
                                Submit Jobs to Leader
                            </Typography>
                            <FormControl
                                fullWidth
                            >
                                <Stack direction="column" spacing={1.5}>
                                    <Box
                                        display={"flex"}
                                        justifyContent={"space-between"}
                                        mb={1}
                                    >
                                        <Typography
                                            sx={{
                                                mt: 1,
                                                color: "var(--shade-2900, #29283B)",
                                                fontSize: "1rem",
                                                fontWeight: "bold",
                                            }}
                                        >
                                            SMILES file (.csv)
                                        </Typography>

                                        <Button variant="text" onClick={handleDownloadSample}>
                                            {"Download Sample"}
                                        </Button>
                                    </Box>

                                    <FileUploader
                                        accept={
                                            ".csv"
                                        }
                                        handleFileUpload={(files) => {
                                            handleFileUpload(files[0], "smiles")
                                        }
                                        }
                                        headerSelector={true}
                                        handleSelectedHeader={setHeaderName}
                                        deleteHandlerDisable={() => setSMILESFile(null)}
                                    />
                                </Stack>
                                <Typography textAlign={"center"} my={1.5}>
                                    -- OR --
                                </Typography>

                                <Typography component="label" htmlFor="smiles-string">
                                    Enter a SMILES string
                                </Typography>
                                <TextField
                                    placeholder="SMILES string"
                                    fullWidth
                                    id="smiles-string"
                                    name="smiles-string"
                                    value={smiles}
                                    onChange={(e) => setSmiles(e.target.value)}
                                />
                                {singleSmilejobStatus === true && (
                                    <>
                                        <Typography mt={1}>
                                            Your request is being processed. You will be notified once it is complete.
                                        </Typography>
                                        <LinearProgress
                                            sx={{ marginTop: "5px" }}
                                            color="success"
                                        />
                                    </>
                                )}
                                <Button
                                    variant="contained"
                                    onClick={handleSubmit}
                                    sx={{
                                        mt: 2,
                                        "&.Mui-disabled": {
                                            background: "#BDADF9",
                                            color: "#fff",
                                        },
                                    }}
                                    disabled={
                                        singleSmilejobStatus === true ||
                                        (!smiles && !SMILESFile)
                                    }
                                >
                                    Submit
                                </Button>
                            </FormControl>
                            <IndefiniteLoader state={inProgress} />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <Grid sx={{ width: "50%", mx: "auto" }}>
                {showSubmitMsg && (
                    <Alert
                        onClose={() => {
                            SetShowSubmitMsg(false);
                        }}
                        sx={{ mt: 2 }}
                        variant="filled"
                        severity="success"
                    >{`Job submitted with id ${jobID}`}</Alert>
                )}
            </Grid>
            <Grid
                container
                alignItems="center"
                justifyContent="center"
                className="jobs-container"
                mt={2}
                px={3}
            >
                <Card sx={{ width: 1 }}>
                    <CardContent>
                        {doLoadJobs && <Loaders type={"table"} />}
                        {
                            !doLoadJobs
                            &&
                            (
                                <JobsTable
                                    jobs={rows}
                                    disableVisualize={false}
                                    setDoLoadJobs={setDoLoadJobs}
                                    nextUrl="visualize"
                                    setErrorMsg={setErrorMsg}
                                    setShowError={setShowError}
                                ></JobsTable>
                            )}
                    </CardContent>
                </Card>
            </Grid>
        </Box>
    )
};


export default LeaderReinvent;
