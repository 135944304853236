interface GenieCardsTypes {
  label: string;
  link: string;
  description: string;
  dashIcon: string;
  navlabel: string;
}

export const genieCards: GenieCardsTypes[] = [
  {
    label: "MAIMol",
    link: "maimol",
    description: "",
    dashIcon: "images/dashcard-images/maimol-dashcard.png",
    navlabel: "MAIMol",
  },
  {
    label: "Ranker",
    link: "ranker",
    description: "",
    dashIcon: "images/dashcard-images/ranker.png",
    navlabel: "Ranker",
  },
  // {
  //   label: "TAGMol",
  //   link: "tagmol",
  //   description: "",
  //   dashIcon: "images/dashcard-images/tagMol-dashcard.png",
  //   navlabel: "TAGMol",
  // },
];
