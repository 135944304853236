import httpCommon from "../../net/http-common";
import { JOB_SERVER_URL } from "../../config";

export const getRankerProperties = ({
  task_id
}: {
  task_id: string
}) => {
  return httpCommon.get(`${JOB_SERVER_URL}/ranker/results`,{
    params: {
      task_id: task_id,
    },
    headers: {
      accept: "application/json",
      "Content-Type": "multipart/form-data",
    },
  });
};