import React from 'react'

function Bioisosteric() {
    return (
        <div className="chemlet mol-props" style={{margin:"1em"}}>
          <iframe
            title="Molecular CoPilot"
            width="100%"
            height="800"
            src={`https://solar-dev.knowdisdata.com/bioiso/`}
          ></iframe>
        </div>
      );
}

export default Bioisosteric
