export const excludedPaths:string[] = [
    "/properties-v2",
    "/protein-info",
    "/",
    "/pdb/prep",
    "/mol-viewer",
    "/models/diffmol",
    "/ligand",
    "/docking",
    "/adme",
    "/models/automol",
    "user/login",
    "/user/signup",
    "/promiscuity",
    "/genie",
    "/pricing",
    "/chemlets",
    "/utilities",
    "/properties-v3",
    "/chemlets/editor",
    "/editor",
    "/lift",
    "/r-groups",
    "/screenie",
    "/leader",
    "/virtual-screening",
  ];

export const navItems:string[] = ["Home", "About", "Contact"];